import { render, staticRenderFns } from "./TambahListMitraGudang.html?vue&type=template&id=15633263&scoped=true&"
import script from "./TambahListMitraGudang.js?vue&type=script&lang=js&"
export * from "./TambahListMitraGudang.js?vue&type=script&lang=js&"
import style0 from "./TambahListMitraGudang.scss?vue&type=style&index=0&id=15633263&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15633263",
  null
  
)

export default component.exports