import {
  required, email, min, max, max_value, minValue,
} from '@validations'
import { VueAutosuggest } from 'vue-autosuggest'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { NoSpace } from '@/libs/helpers'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.id'
import { komshipAxiosIns } from '@/libs/axios'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  components: {
    VueAutosuggest,
    ValidationProvider,
    ValidationObserver,
    Cleave,
    vSelect,
  },
  data() {
    return {
      email,
      required,
      min,
      max,
      max_value,
      minValue,
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'ID',
        },
      },
      phoneCodeList: ['+62'],
      phoneCode: '+62',
      isWhatsappPic: null,
      isWhatsappOwner: null,
      is_whatsapp: this.isWhatsapp === 'valid' ? 1 : 0,
      requirePicPhone: false,
      requireOwnerPhone: false,
      picPhone: '',
      ownerPhone: '',
      messageErrorPhone: false,
      customerPhonePasteMode: false,
      btnSubmitDisabled: false,
      filteredOptionsDesti: [],
      queryDestination: '',
      selectedDestination: null,
      inputPropsDestination: {
        id: 'inputPropsDestination',
        class: 'form-control',
        placeholder: 'Masukan Kode Pos/Kecamatan',
      },
      limitOptDestination: 10,
      optionDestination: [],
      optionsKetersediaan: [
        { text: 'Penuh', value: 0 },
        { text: 'Tersedia', value: 1 },
      ],
      optionsGender: [
        { value: 'Laki-laki', text: 'Laki-laki' },
        { value: 'Perempuan', text: 'Perempuan' },
      ],
      optionBuildingType: [
        {
          value: null,
          label: 'Pilih jenis bangunan',
        },
      ],
      optionsOwnership: [
        {
          value: null,
          label: 'Pilih jenis kepemilikan',
        },
      ],
      dataAkun: {
        email: '',
        username: '',
      },
      dataFulfillment: {
        warehouse_name: '',
        avability: null,
        pic_name: '',
        pic_phone: '',
        description: '',
        image_warehouse: null,
        image_logo: null,
      },
      dataOwner: {
        owner: '',
        gender: null,
        phone_number: '',
        nik: '',
        image_ktp_url: null,
      },
      dataProperti: {
        destination_id: '',
        detail_addres: '',
        building_area: '',
        building_type: null,
        ownership: null,
      },
      imageFieldFormType: {
        fulfillmentLogo: 'fulfillmentLogo',
        fulfillmentWarehouse: 'fulfillmentWarehouse',
        ownerKTP: 'ownerKTP',
      },
      prevImg: {
        logo: null,
        warehouse: null,
        ktp: null,
      },
      messageUsername: '',
      showMessage: false,
      id: '',
      DisableSubmit: false,
      NoSpace,
    }
  },
  watch: {
    queryDestination: {
      handler(val) {
        if (!val) {
          this.selectedDestination = ''
          this.dataProperti.destination_id = null
        }
        this.fetchDataDestination()
      },
    },
  },
  mounted() {
    this.fetchDataSelectOption()
    this.fetchDataDestination()
  },
  methods: {
    getDestinationValue(suggestion) {
      const { item } = suggestion
      this.selectedDestination = item
      this.dataProperti.destination_id = item.id
      return item.label
    },
    onInputChangeDestination(text) {
      if (text === '' || text === undefined) {
        return
      }
      const filtered = this.optionDestination
        .filter(
          item => item.label.toLowerCase().indexOf(text.toLowerCase()) > -1,
        )
        .slice(0, this.limitOptDestination)
      this.filteredOptionsDesti = [
        {
          data: filtered,
        },
      ]
    },
    fetchDataDestination() {
      const filtered = this.optionDestination
        .filter(
          item => item.label
            .toLowerCase()
            .indexOf(this.queryDestination.toLowerCase()) > -1,
        )
        .slice(0, this.limitOptDestination)
      if (!filtered.length) {
        this.$http('/kompack/destination', {
          params: { search: this.queryDestination },
        })
          .then(({ data }) => {
            this.filteredOptionsDesti = [{ data: data.data }]
            this.optionDestination = data.data
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'Failed',
                text: 'Galat tambah data mitra gudang',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    fetchDataSelectOption() {
      this.$http('/kompack/select-option/ownership')
        .then(({ data }) => {
          const ownerShip = [
            {
              value: null,
              label: 'Pilih jenis kepemilikan',
            },
          ]

          data.data.forEach(x => {
            ownerShip.push({
              value: x.id,
              label: x.name,
            })
          })
          this.optionsOwnership = ownerShip
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failed',
              text: 'Galat tambah data mitra gudang',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        })
      this.$http('/kompack/select-option/building')
        .then(({ data }) => {
          const buildingType = [
            {
              value: null,
              label: 'Pilih jenis bangunan',
            },
          ]
          data.data.forEach(x => {
            buildingType.push({
              value: x.id,
              label: x.name,
            })
          })
          this.optionBuildingType = buildingType
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failed',
              text: 'Galat tambah data mitra gudang',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        })
    },
    savedatalist() {
      this.btnSubmitDisabled = true
      this.DisableSubmit = true
      this.$refs.tambahlistdata.validate().then(success => {
        if (success) {
          if (
            this.dataOwner.image_ktp_url
            && this.dataFulfillment.image_warehouse
            && this.dataFulfillment.image_logo
          ) {
            //
          } else {
            this.DisableSubmit = true
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'Galat',
                text: 'Check ada file yang belum diupload',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
            this.btnSubmitDisabled = false
          }
          // body data
          const formData = new FormData()
          formData.append('email', this.dataAkun.email)
          formData.append('username', this.dataAkun.username)
          formData.append('owner', this.dataOwner.owner)
          formData.append('gender', this.dataOwner.gender)
          formData.append('phone_number', this.dataOwner.phone_number)
          formData.append('nik', this.dataOwner.nik)
          formData.append('image_ktp_url', this.dataOwner.image_ktp_url) // string ($binary)
          formData.append('image_logo', this.dataFulfillment.image_logo) // string ($binary)
          formData.append('name', this.dataFulfillment.warehouse_name)
          formData.append('availability', this.dataFulfillment.avability)
          formData.append('pic_name', this.dataFulfillment.pic_name)
          formData.append('pic_phone', this.dataFulfillment.pic_phone)
          formData.append('description', this.dataFulfillment.description)
          this.dataFulfillment.image_warehouse.forEach(xt => {
            formData.append('image_warehouse[]', xt) // array<string ($binary)>
          })
          formData.append(
            'destination_id',
            Number.isNaN(parseInt(this.dataProperti.destination_id, 10))
              ? this.dataProperti.destination_id
              : parseInt(this.dataProperti.destination_id, 10),
          )
          formData.append('detail_address', this.dataProperti.detail_addres)
          formData.append(
            'building_area',
            Number.isNaN(parseInt(this.dataProperti.building_area, 10))
              ? 0
              : parseInt(this.dataProperti.building_area, 10),
          )
          formData.append('building_type', this.dataProperti.building_type)
          formData.append('ownership', this.dataProperti.ownership)

          // calling API untuk tambah mitra gudang
          this.$http
            .post('kompack/store-mitra-warehouse', formData, {
              headers: {
                'content-type': 'multipart/form-data',
              },
            })
            .then(({ data }) => {
              this.id = data.data.mitra_id
              this.$bvModal.show('modal-tambahmitra-success')
            })
            .catch(err => {
              if (err?.response?.data) {
                const dataError = Object.values(
                  err.response.data.errors,
                ).map(x => x.join())
                dataError.forEach(dt => {
                  this.$toast({
                    component: ToastificationContentVue,
                    props: {
                      title: 'Galat',
                      text: dt,
                      icon: 'AlertCircleIcon',
                      variant: 'danger',
                    },
                  })
                })
              }
              this.$toast({
                component: ToastificationContentVue,
                props: {
                  title: 'Failed',
                  text: 'Galat tambah data mitra gudang',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              })
              this.btnSubmitDisabled = false
              this.DisableSubmit = true
            })
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failed',
              text: 'Galat, ada form belum diisi atau salah isi',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
          this.btnSubmitDisabled = false
          this.DisableSubmit = true
        }
      })
    },
    previewLogo(evChange, type) {
      const file = evChange.target.value
      const fileExtension = file.substr(file.lastIndexOf('.') + 1)
      if (fileExtension !== 'jpg' && fileExtension !== 'jpeg' && fileExtension !== 'png' && fileExtension !== 'gif') {
        // eslint-disable-next-line no-alert
        alert('Hanya dapat upload file dengan ekstensi JPG, PNG, JPEG and GIF')
      } else {
        const [dataimg] = evChange.target.files
        let url = null
        let multiFile = []
        switch (type) {
          case this.imageFieldFormType.fulfillmentLogo:
            if (this.validateSize(dataimg)) {
              //
            } else {
              return
            }
            this.dataFulfillment.image_logo = dataimg
            url = URL.createObjectURL(dataimg)
            this.prevImg.logo = url
            break
          case this.imageFieldFormType.fulfillmentWarehouse:
            if (
              Array.isArray(this.dataFulfillment.image_warehouse)
              && this.dataFulfillment.image_warehouse.length > 0
            ) {
              multiFile = [...this.dataFulfillment.image_warehouse]
            }
            evChange.target.files.forEach(fl => {
              if (this.validateSize(fl)) {
                multiFile.push(fl)
              }
            })
            if (multiFile.length > 8) {
              this.$toast({
                component: ToastificationContentVue,
                props: {
                  title: 'Galat',
                  text: 'Tidak bisa unggah lebih dari 8 berkas gambar',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              })
              return
            }
            this.dataFulfillment.image_warehouse = multiFile
            url = []
            multiFile.forEach(x => {
              url.push(URL.createObjectURL(x))
            })
            this.prevImg.warehouse = url
            break
          case this.imageFieldFormType.ownerKTP:
            if (this.validateSize(dataimg)) {
              //
            } else {
              return
            }
            this.dataOwner.image_ktp_url = dataimg
            url = URL.createObjectURL(dataimg)
            this.prevImg.ktp = url
            break
          default:
            break
        }
      }
    },
    isNumber(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode
      if (
        charCode > 31
        && (charCode < 48 || charCode > 57)
        && charCode !== 48
      ) {
        evt.preventDefault()
      }
      return true
    },
    validateSize(dt) {
      // in MiB
      const fileSize = dt.size / 1024 / 1024
      if (fileSize > 2) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Galat',
            text: 'Ukuran berkas tidak boleh lebih dari 2 MB',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        return false
      }
      return true
    },
    handleDeleteImg(type) {
      switch (type) {
        case this.imageFieldFormType.ownerKTP:
          this.prevImg.ktp = null
          break
        case this.imageFieldFormType.fulfillmentLogo:
          this.prevImg.logo = null
          break
        default:
          break
      }
    },
    handleDeleteAllImgWarehouse() {
      this.prevImg.warehouse = []
      this.dataFulfillment.image_warehouse = []
    },
    handleDeleteImgWarehouse(indexdt, dtImg) {
      const data = `${indexdt}`
      if (data) {
        const oldData = {
          prevImg: [...this.prevImg.warehouse],
          dataFulfillment: [...this.dataFulfillment.image_warehouse],
        }
        const newData = {
          prevImg: oldData.prevImg.filter((_, indx) => indx !== indexdt),
          dataFulfillment: oldData.dataFulfillment.filter(
            (_, indx) => indx !== indexdt,
          ),
        }
        this.prevImg.warehouse = newData.prevImg
        this.dataFulfillment.image_warehouse = newData.dataFulfillment.filter(
          x => x instanceof File,
        )
        this.dataFulfillment.image_warehouse = newData.dataFulfillment
      } else {
        this.prevImg.warehouse = []
        this.dataFulfillment.image_warehouse = []
      }
    },
    showModalBatal() {
      this.$bvModal.show('modal-tambahmitra-warning')
    },
    checkEmail: _.debounce(function () {
      this.showMessage = false
      const cekEmail = String(this.dataAkun.email)
      this.emailValid = cekEmail
        .toLowerCase()
      // eslint-disable-next-line no-useless-escape
        .match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) != null
      if (this.emailValid) {
        this.$http
          .get(`/kompack/check-email?email=${cekEmail}`)
          .then(response => {
            if (response.status === 200) {
              if (response.data.data.role_id === 1) {
                this.showMessage = true
                this.messageUsername = 'Admin Komerce'
              }
              if (response.data.data.role_id === 2) {
                this.showMessage = true
                this.messageUsername = 'Manajemen Komerce'
              }
              if (response.data.data.role_id === 3) {
                this.showMessage = true
                this.messageUsername = 'SDM Komerce'
              }
              if (response.data.data.role_id === 4) {
                this.showMessage = true
                this.messageUsername = 'Partner Komerce'
              }
              if (response.data.data.role_id === 5) {
                this.showMessage = true
                this.messageUsername = 'Talent Global'
              }
              if (response.data.data.role_id === 6) {
                this.showMessage = true
                this.messageUsername = 'Mitra Komerce'
              }
              this.dataAkun.username = response.data.data.username
            }
          })
      }
    }, 500),
    isAlphabet(e) {
      const char = String.fromCharCode(e.keyCode)
      if (/^[A-Za-z]+$/.test(char)) {
        return true
      }
      if (/\s/.test(char)) {
        return true
      }
      e.preventDefault()
      return false
    },
    validationSubmit() {
      let result = true
      if (
        this.dataAkun.email === ''
        || this.dataAkun.username === ''
        || this.dataFulfillment.warehouse_name === ''
        || this.dataFulfillment.avability === null
        || this.dataFulfillment.pic_name === ''
        || this.dataFulfillment.pic_phone === ''
        || this.dataFulfillment.description === ''
        || this.prevImg.warehouse.length === 0
        || this.prevImg.logo === null
        || this.prevImg.ktp === null
        || this.dataOwner.owner === ''
        || this.dataOwner.gender === null
        || this.dataOwner.phone_number === ''
        || this.dataOwner.nik === ''
        || this.dataOwner.image_ktp_url === ''
        || this.dataProperti.destination_id === ''
        || this.dataProperti.detail_addres === ''
        || this.dataProperti.building_area === ''
        || this.dataProperti.building_type === null
        || this.dataProperti.ownership === null
      ) {
        result = true
      } else {
        result = false
      }
      return result
    },
    async checkWhatsappPic() {
      await komshipAxiosIns
        .post(`/v1/check-wa?phone_no=${this.picPhone}`)
        .then(res => {
          const { data } = res.data
          this.isWhatsappPic = data
          this.messageErrorPhone = false
        })
        .catch(error => {
          const { data } = error.response.data
          this.isWhatsappPic = data
          this.messageErrorPhone = false
          if (error.response.data.code !== 1001) {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: error,
                variant: 'danger',
              },
            })
          }
        })
    },
    async checkWhatsappOwner() {
      await komshipAxiosIns
        .post(`/v1/check-wa?phone_no=${this.ownerPhone}`)
        .then(res => {
          const { data } = res.data
          this.isWhatsappOwner = data
          this.messageErrorPhone = false
        })
        .catch(error => {
          const { data } = error.response.data
          this.isWhatsappOwner = data
          this.messageErrorPhone = false
          if (error.response.data.code !== 1001) {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: error,
                variant: 'danger',
              },
            })
          }
        })
    },
    formatPhonePic: _.debounce(function () {
      if (this.picPhone.length < 9) {
        this.messageErrorPhone = true
        this.requirePicPhone = true
        this.isWhatsappPic = null
      } else {
        this.messageErrorPhone = false
        this.requirePicPhone = false
        this.checkWhatsappPic()
      }
      if (this.customerPhonePasteMode === true) {
        this.picPhone = this.customerPhonePaste
      }
      this.customerPhonePasteMode = false
    }, 1000),
    formatPhoneOwner: _.debounce(function () {
      if (this.ownerPhone.length < 9) {
        this.messageErrorPhone = true
        this.requireOwnerPhone = true
        this.isWhatsappOwner = null
      } else {
        this.messageErrorPhone = false
        this.requireOwnerPhone = false
        this.checkWhatsappOwner()
      }
      if (this.customerPhonePasteMode === true) {
        this.ownerPhone = this.customerPhonePaste
      }
      this.customerPhonePasteMode = false
    }, 1000),
    checkValidationPicPhone(e) {
      if (e.target.value.length < 9) {
        this.requirePicPhone = true
        this.messageErrorPhone = true
      } else {
        this.requirePicPhone = false
        this.picPhone = Number(e.target.value)
        this.checkWhatsappPic()
      }
    },
    checkValidationOwnerPhone(e) {
      if (e.target.value.length < 9) {
        this.requireOwnerPhone = true
        this.messageErrorPhone = true
      } else {
        this.requireOwnerPhone = false
        this.ownerPhone = Number(e.target.value)
        this.checkWhatsappOwner()
      }
    },
    valueFormatPhone(e) {
      if (e.target.value.length < 9) {
        this.messageErrorPhone = true
      } else {
        this.messageErrorPhone = false
      }
    },
    validateInputPhone(e) {
      const charCode = e.which ? e.which : e.keyCode
      if (e.target.value.length > 13) {
        e.preventDefault()
      }
      if (charCode === 101 || charCode === 46 || charCode === 44) {
        e.preventDefault()
      }
      return true
    },
    formatterPicPhone(e) {
      this.customerPhonePasteMode = true
      this.picPhone = ''
      this.customerPhonePaste = ''
      this.customerPhonePaste = e.clipboardData
        .getData('text')
        .replace(/\D/g, '')
      if (this.customerPhonePaste.charAt(0) === '0') {
        this.customerPhonePaste = this.customerPhonePaste.substr(
          1,
          this.customerPhonePaste.length,
        )
      } else {
        this.customerPhonePaste = this.customerPhonePaste.substr(
          0,
          this.customerPhonePaste.length,
        )
      }
      if (this.customerPhonePaste.charAt(0) === '6') {
        this.customerPhonePaste = this.customerPhonePaste.substr(
          2,
          this.customerPhonePaste.length,
        )
      } else {
        this.customerPhonePaste = this.customerPhonePaste.substr(
          0,
          this.customerPhonePaste.length,
        )
      }
    },
    formatterOwnerPhone(e) {
      this.customerPhonePasteMode = true
      this.ownerPhone = ''
      this.customerPhonePaste = ''
      this.customerPhonePaste = e.clipboardData
        .getData('text')
        .replace(/\D/g, '')
      if (this.customerPhonePaste.charAt(0) === '0') {
        this.customerPhonePaste = this.customerPhonePaste.substr(
          1,
          this.customerPhonePaste.length,
        )
      } else {
        this.customerPhonePaste = this.customerPhonePaste.substr(
          0,
          this.customerPhonePaste.length,
        )
      }
      if (this.customerPhonePaste.charAt(0) === '6') {
        this.customerPhonePaste = this.customerPhonePaste.substr(
          2,
          this.customerPhonePaste.length,
        )
      } else {
        this.customerPhonePaste = this.customerPhonePaste.substr(
          0,
          this.customerPhonePaste.length,
        )
      }
    },
  },
}
